<template>
  <v-container class="containerLogin" fluid fill-height>
    <template v-if="loading">
      <div class="mx-auto">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
    </template>
    <template v-else>
      <v-row v-if="!loading" align="center" style="height: 100vh;" no-gutters>
        <v-col md="7" sm="12" cols="12">
          <v-img
            class="hidden-sm-and-down img-login"
            src="@/assets/background-login.png"
          ></v-img>
          <v-img
            class="hidden-md-and-up img-login"
            src="@/assets/background-login.png"
            max-height="300"
            max-width="300"
          ></v-img>
        </v-col>
        <!-- Primeira parte para alterar a senha -->
        <v-col v-if="!alterarSenha" md="5" sm="12" cols="12">
          <v-card class="pa-2" flat tile>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              @submit.prevent="validateBeforeSubmit"
            >
              <v-card flat>
                <v-card-title class="text-title">
                  Informe seu e-mail para recebimento de mensagem para
                  redefinição de sua senha.
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="email"
                        :rules="[rules.required, rules.email]"
                        label="E-mail cadastrado"
                      ></v-text-field>
                    </v-col>
                    <v-col md="6" sm="6" cols="6">
                      <v-btn
                        :disabled="!valid"
                        :loading="loading"
                        class="text-capitalize"
                        color="primary"
                        type="submit"
                      >
                        Enviar e-mail
                      </v-btn>
                    </v-col>
                    <v-col md="6" sm="6" cols="6" class="text-right">
                      <a href="/login" class="body-2">
                        Voltar para login
                      </a>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-form>
          </v-card>
        </v-col>
        <!-- Segunda parte para alterar a senha -->
        <v-col v-if="alterarSenha" md="5" sm="12" cols="12">
          <v-card class="pa-2" flat tile>
            <v-form
              ref="formSenha"
              v-model="valid"
              lazy-validation
              @submit.prevent="validateNovaSenha"
            >
              <v-card flat>
                <v-card-title>
                  Favor inserir uma nova senha
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="novaSenha"
                        label="Senha"
                        type="password"
                        name="senha"
                        :rules="[rules.required]"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="confirmaSenha"
                        label="Confirma Senha"
                        type="password"
                        name="confirmaSenha"
                        :rules="[rules.required, rules.confirm]"
                      />
                    </v-col>
                    <v-col md="6" sm="6" cols="6">
                      <v-btn
                        :disabled="!valid"
                        :loading="loading"
                        class="text-capitalize"
                        color="primary"
                        type="submit"
                      >
                        Alterar senha
                      </v-btn>
                    </v-col>
                    <v-col md="6" sm="6" cols="6" class="text-right">
                      <a href="/login" class="body-2">
                        Voltar para login
                      </a>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { UserService } from "@/services";

export default {
  data() {
    return {
      valid: false,
      loading: false,
      novaSenha: null,
      confirmaSenha: null,
      alterarSenha: false,
      codigo: "",
      email: "",
      rules: {
        required: value => !!value || "Campo obrigatório.",
        confirm: value =>
          (!!value && this.novaSenha === value) ||
          "As duas senhas precisam ser iguais!",
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Endereço de email inválido.";
        }
      }
    };
  },
  beforeMount() {
    this.loading = true;
    this.email = this.$route.params.email;
    this.codigo = this.$route.params.codigo;
    if (this.email && this.codigo) {
      this.alterarSenha = true;
    } else {
      this.alterarSenha = false;
    }
    this.loading = false;
  },
  methods: {
    validateBeforeSubmit() {
      if (this.$refs.form.validate()) {
        this.esqueciMinhaSenha();
      } else {
        this.$root.showErro("Informe um e-mail");
      }
    },
    validateNovaSenha() {
      if (this.$refs.formSenha.validate()) {
        this.enviarNovaSenha();
      } else {
        this.showErro("As duas senhas precisam ser iguais!");
      }
    },
    esqueciMinhaSenha() {
      this.loading = true;
      UserService.esqueciMinhaSenha(this.email)
        .then(response => {
          this.$root.showSucesso(response.data);
          setTimeout(() => {
            this.$router.push("/login");
          }, 2000);
        })
        .catch(error => {
          this.$root.showErro(error.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    enviarNovaSenha() {
      this.loading = true;
      UserService.alterarSenha(this.email, this.codigo, this.novaSenha)
        .then(response => {
          this.$root.showSucesso(response.data);
          setTimeout(() => {
            this.$router.push("/login");
          }, 2000);
        })
        .catch(error => {
          this.$root.showErro(error.data);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped>
.containerLogin {
  padding: 0;
  margin: 0;
  background: #ffffff;
}
.img-login {
  position: relative;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
}
.text-title {
  word-break: normal;
}
</style>
